import React, { useEffect, useState } from "react";
import RoomwiseADRReportTable from "./RoomwiseADRReportTable";
import { useGetMonthlyRevenueSalesReport } from "../../../api/RoomwiseADRReport/Queries";
import { useHostelList } from "../../../api/dataSource";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import { dateValidator } from "../../../utility/Utils";
// import { CSVLink } from "react-csv";
import { selectThemeColors } from "@utils";
import toaster from "@components/common/Toaster";
import Select from "react-select";

const RoomwiseADRReport = () => {
  const [fromDate, setFromDate] = useState(dateFormatter(new Date()));
  const [toDate, setToDate] = useState(dateFormatter(new Date()));
  const [searchFromDate, setSearchFromDate] = useState(
    dateFormatter(new Date())
  );
  const [searchToDate, setSearchToDate] = useState(dateFormatter(new Date()));
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filterFields, setFilterFields] = useState({});
  const { data: allHostelsData } = useHostelList();

  useEffect(() => {
    if (allHostelsData?.getHostelList?.length) {
      let HostelsList = allHostelsData?.getHostelList?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList]);
  let { data, refetch } = useGetMonthlyRevenueSalesReport(
    filterFields?.searchFromDate,
    filterFields?.searchToDate,
    filterFields?.hostelId
  );

  useEffect(() => {
    if (data?.getMonthlyRevenueSalesReport?.length > 0) {
      setTableData(data?.getMonthlyRevenueSalesReport);
    } else {
      setTableData([]);
    }
  }, [data]);

  useEffect(() => {
    if (dateValidator(fromDate, toDate)) {
      setSearchFromDate(fromDate);
      setSearchToDate(toDate);
    } else {
      toaster("error", "Invalid Dates");
    }
  }, [fromDate, toDate]);

  const handleSearch = () => {
    const data = {
      searchFromDate,
      searchToDate,
      hostelId: hostel?.map((item) => item?.value),
    };
    setFilterFields(data);
  };

  const handleResetData = () => {
    setSearchFromDate(dateFormatter(new Date()));
    setSearchToDate(dateFormatter(new Date()));
    setToDate(dateFormatter(new Date()));
    setFromDate(dateFormatter(new Date()));
    setFilterFields({});
    setHostel([]);
    setTableData([]);
  };

  return (
    localStorage.getItem("modules")?.includes("Reports") && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                From Date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromDate}
                onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
                id="fromDate"
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                To Date *
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toDate}
                onChange={(date) => setToDate(dateFormatter(new Date(date)))}
                id="toDate"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                Hostel
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                closeMenuOnSelect={false}
                options={hostelsList}
                onChange={(value) => setHostel(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col style={{ marginTop: "22px", display: "flex" }}>
            <Button.Ripple
              onClick={handleSearch}
              style={{
                height: "40px",
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="primary"
              appearance="danger"
              type="button"
            >
              Search
            </Button.Ripple>
            <Button.Ripple
              onClick={handleResetData}
              style={{
                height: "40px",
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="danger"
              type="button"
            >
              Reset
            </Button.Ripple>

            {/* <FormGroup>
              <CSVLink
                // headers={tableHeaderData}
                data={tableData}
                filename={"Source_wise_sale_report.csv"}
              >
                <Button.Ripple
                 style={{
                  backgroundColor: "#ffe700",
                  height: "40px",
                  marginLeft:"10px",
                  borderRadius: "5px",
                  padding: "10px 40px",
                  outline: "none",
                  border: "0",
                }}
                color="light"
                appearance="danger"
                type="button"
                >
                  Export
                </Button.Ripple>
              </CSVLink>
            </FormGroup> */}
          </Col>
        </Row>
        {tableData.length > 0 ? (
          <RoomwiseADRReportTable data={tableData} refetch={refetch} />
        ) : (
          <h2 style={{ textAlign: "center", marginTop: "150px" }}>
            No Record Found
          </h2>
        )}
      </div>
    )
  );
};

export default RoomwiseADRReport;
