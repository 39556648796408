import gql from "graphql-tag";

export const GET_PAGINATED_USER_REQUESTS_LIST = gql`
  query getPaginatedUserRequestList($filter: filter) {
    getPaginatedUserRequestList(filter: $filter) {
      data {
        _id
        reservationId
        hostel {
          _id
          name
        }
        requestCategory
        request {
          requestName
          requestDescription
          requestIcon
          tat
        }
        requestStatus
        guestComment
        requestImages
        staffRemark
        staffName
        logs
        ezeebookings
        requestNumber
        isCritical
        createdAt
        updatedAt
        rating
        staffImages {
          image
        }
        roomNo
        room
        guestName
      }
      count
      limit
      page
    }
  }
`;

//added by nitish for export
export const GET_ALL_USER_REQUESTS_LIST = gql`
  query getAllUserRequestList($filter: filter) {
    getAllUserRequestList(filter: $filter) {
      data {
        _id
        reservationId
        hostel {
          _id
          name
        }
        requestCategory
        request {
          requestName
          requestDescription
          requestIcon
          tat
        }
        requestStatus
        guestComment
        requestImages
        staffRemark
        logs
        ezeebookings
        requestNumber
        isCritical
        createdAt
        updatedAt
        createdAt
        rating
        staffImages {
          image
        }
      }
    }
  }
`;
