import React, { Fragment, useState } from "react";
import { Tooltip, Table as ReactTable } from "reactstrap";
import { useSkin } from "@hooks/useSkin";
import "./style.css";

const Table = ({
  datesToShowToTableHeader,
  dateRanges,
  data,
  isloading,
  isHostelSelected,
}) => {
  const [skin] = useSkin();
  const backgroundColor = skin === "light" ? "#F3F2F7" : "#343D55";
  const [expandedRows, setExpandedRows] = useState({});
  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const toggleAccordion = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const renderTooltip = (id, roomName) => (
    <Tooltip
      placement="top"
      isOpen={tooltipOpen[id] || false}
      target={id}
      toggle={() => toggleTooltip(id)}
    >
      {roomName || "-"}
    </Tooltip>
  );

  // if (isloading) return <div>Loading...</div>; // Replace with a spinner if needed

  const getLableOfTheTbody = () => {
    if (isHostelSelected && data?.length === 0) {
      return "No Data Found";
    } else {
      return "Kindly Search Hostel";
    }
  };

  return (
    <section style={{ minWidth: "max-content", overflowX: "auto" }}>
      <ReactTable bordered>
        <thead style={{ position: "sticky", top: 0 }}>
          <tr>
            <th>Room Name</th>
            <th> </th>
            {datesToShowToTableHeader.map((day, index) => (
              <th key={index}>{day}</th>
            ))}
          </tr>
        </thead>
        {data?.length ? (
          <tbody>
            {data.map((hostel, hIdx) =>
              Object.keys(hostel?.pricebyRoomName).map((roomName, roomIdx) => (
                // Doing React Fragment to render multiple rows with the appropriate index to resolve the issue of missing id
                <React.Fragment key={`hostel-${hIdx}-room-${roomIdx}`}>
                  <tr>
                    <td
                      rowSpan={
                        hostel?.pricebyRoomName[roomName]?.competitors
                          ? hostel?.pricebyRoomName[roomName]?.competitors
                              .length + 2
                          : 2
                      }
                    >
                      {roomName}
                    </td>
                    <td>The Hosteller</td>
                    {dateRanges.map((day, dayIdx) => (
                      <td key={`th-${dayIdx}`}>
                        {hostel.pricebyRoomName[roomName]?.prices?.[day]?.[0]
                          ?.rate || "-"}
                      </td>
                    ))}
                  </tr>

                  <tr>
                    <td>Expected Price</td>
                    {dateRanges.map((day, dayIdx) => (
                      <td key={`avg-${dayIdx}`}>
                        {hostel.pricebyRoomName[roomName]?.expectedPrice?.[
                          day
                        ] || "-"}
                      </td>
                    ))}
                  </tr>

                  {hostel.pricebyRoomName[roomName]?.competitors?.map(
                    (competitor, compIdx) => (
                      <tr key={`comp-${compIdx}`}>
                        <td>{competitor.competitor}</td>
                        {dateRanges.map((day, dayIdx) => {
                          const tooltipId = `Tooltip-${hIdx}-${roomIdx}-${compIdx}-${dayIdx}`;
                          return (
                            <td
                              id={tooltipId} // Assign the ID here
                              key={`comp-price-${compIdx}-${dayIdx}`}
                              onMouseEnter={() =>
                                setTooltipOpen({
                                  ...tooltipOpen,
                                  [tooltipId]: true,
                                })
                              }
                              onMouseLeave={() =>
                                setTooltipOpen({
                                  ...tooltipOpen,
                                  [tooltipId]: false,
                                })
                              }
                            >
                              {competitor?.prices[day]?.[0]?.rate || "-"}
                              {renderTooltip(tooltipId, competitor?.roomName)}
                            </td>
                          );
                        })}
                      </tr>
                    )
                  )}
                </React.Fragment>
              ))
            )}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td
                colSpan={datesToShowToTableHeader.length}
                style={{ padding: "20px" }}
              >
                <h2>{getLableOfTheTbody()}</h2>
              </td>
            </tr>
          </tbody>
        )}
      </ReactTable>
    </section>
  );
};

export default Table;
