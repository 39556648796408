import React, { useEffect, useState } from "react";
import GOPurchaseTable from "./GOPurchaseTable";
import TablePagination from "@components/common/TablePagination";
import { useHistory } from "react-router";
import { useGetPaginatedGOPurchaseRequest } from "../../../api/GOPurchaseRequest/Queries";
import { useGetGOPurchaseRequestReport } from "../../../api/GOPurchaseRequest/Mutation";
import AddEditModal from "./AddEditModal";
import { Button, Col, FormGroup, Label, Row, Input } from "reactstrap";
import Select from "react-select";
import { useHostelList } from "../../../api/dataSource";
import { selectThemeColors } from "@utils";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import { useGetAllCapexVendorNames } from "../../../api/OpsHostelVendor/Queries";
import ReactSlider from "react-slider";
import "./MultiRangeSlider.css";

const GOPurchase = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [hostelsList, setHostelsList] = useState([]);
  const [statusFilter, setStatusFilter] = useState(null);
  const [hostel, setHostel] = useState([]);
  const [requestId, setRequestId] = useState("");
  const [paymentStatus, setPaymentStatus] = useState();
  const [urgency, setUrgency] = useState(null);
  const [filterFeilds, setFilterFeilds] = useState({
    hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
  });

  const [openModal, setOpenModal] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [id, setId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [requestStartDate, setRequestStartDate] = useState(null);
  const [requestEndDate, setRequestEndDate] = useState(null);
  const { data: allHostelsData } = useHostelList();
  const [vendorNameOption, setVendorNameOption] = useState([]);
  const [vendorName, setVendorName] = useState([]);
  const [minValue, setMinValue] = useState(1);
  const [maxValue, setMaxValue] = useState(500000);
  const [head, setHead] = useState(null);
  const [subHead, setSubHead] = useState(null);
  const [subHeadType, setSubHeadType] = useState(null);
  const [paymentDue, setPaymentDue] = useState(null);
  const [completionStartRange, setCompletionStartRange] = useState(null);
  const [completionEndDateRange, setCompletionEndDateRange] = useState(null);
  const [remark, setRemark] = useState("");
  const [itemName, setItemName] = useState("");
  const { data, loading, refetch } = useGetPaginatedGOPurchaseRequest(
    limit,
    page,
    filterFeilds
  );

  const exportInputFilter = {
    filterFeilds,
  };

  const { data: vendorData, refetch: VendorRefetch } =
    useGetAllCapexVendorNames();

  const history = useHistory();

  const userRole = localStorage.getItem("role");

  const userDepartment = localStorage.getItem("department");

  const count = data?.getPaginatedGOPurchaseRequest?.count;

  const [getGOPurchaseRequestReportDataHandler, { data: exportData }] =
    useGetGOPurchaseRequestReport();

  const consolidatedTotalAmount =
    data?.getPaginatedGOPurchaseRequest?.aggregatedAmount?.totalAmount;

  const consolidatedTotalAmountPaid =
    data?.getPaginatedGOPurchaseRequest?.aggregatedAmount?.totalAmountPaid;

  const consolidatedTotalTdsAmount =
    data?.getPaginatedGOPurchaseRequest?.aggregatedAmount?.totalTdsAmount;

  const consolidatedTotalDeductionAmount =
    data?.getPaginatedGOPurchaseRequest?.aggregatedAmount?.deductionAmount;

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  const statusDropDownList = [
    { label: "All", value: "All" },
    { label: "Pending", value: "Pending" },
    { label: "Quotation Added", value: "Quotation Added" },
    { label: "Purchase Rejected", value: "Purchase Rejected" },
    { label: "AM Approved", value: "AM Approved" },
    { label: "AM Rejected", value: "AM Rejected" },
    { label: "RM Approved", value: "RM Approved" },
    { label: "RM Rejected", value: "RM Rejected" },
    { label: "Purchase Approved", value: "Purchase Approved" },
    { label: "CEO Rejected", value: "CEO Rejected" },
    // { label: "Payment Due", value: "Payment Due" },
    // { label: "Payment Done", value: "Payment Done" },
    { label: "Material Dispatched", value: "Material Dispatched" },
    {
      label: "Request Partially Completed",
      value: "Request Partially Completed",
    },
    {
      label: "Partial Request Resolved",
      value: "Partial Request Resolved",
    },
    { label: "Request Completed", value: "Request Completed" },
  ];

  const paymentStatusList = [
    { label: "Payment Due", value: "Payment Due" },
    { label: "Payment Done", value: "Payment Done" },
    { label: "Payment Rejected", value: "Payment Rejected" },
    { label: "Payment Done - On Account", value: "Payment Done - On Account" },
  ];

  const urgencyList = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ];

  const paymentDueOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  useEffect(() => {
    if (data?.getPaginatedGOPurchaseRequest?.data?.length > 0) {
      setTableData(data?.getPaginatedGOPurchaseRequest?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getPaginatedGOPurchaseRequest?.count / limit));
  }, [
    data?.getPaginatedGOPurchaseRequest?.data,
    data?.getPaginatedGOPurchaseRequest?.count,
    limit,
  ]);

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList, limit, page]);

  useEffect(() => {
    if (id && data?.getPaginatedGOPurchaseRequest?.data?.length > 0) {
      let findData = data?.getPaginatedGOPurchaseRequest?.data?.find(
        (item) => item._id === id
      );
      setEditData(findData);
      setId(id);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedGOPurchaseRequest?.data, id]);

  useEffect(() => {
    if (exportData?.getGOPurchaseRequestReport?.data) {
      if (exportData?.getGOPurchaseRequestReport?.data.length > 0) {
        const byteArray = new Uint8Array(
          exportData?.getGOPurchaseRequestReport?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `CAPEX_Purchase_Request_Export.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  }, [exportData]);

  const handleSearch = () => {
    const filterFeilds = {
      hostelIdArray:
        hostel.length > 0
          ? hostel?.map((hostel) => hostel?.value)
          : JSON.parse(localStorage.getItem("hostels")),

      status: statusFilter?.map((obj) => obj?.value),
      requestId,
      paymentStatus: paymentStatus?.value,
      urgency: urgency?.map((urgency) => urgency?.value),
      requestStartDate,
      requestEndDate,
      vendorIDs:
        vendorName.length > 0 ? vendorName?.map((item) => item?.value) : [],
      head: head?.value,
      subHead: subHead?.value,
      subHeadType: subHeadType?.value,
      paymentDue: paymentDue?.value,
      completionStartRange,
      completionEndDateRange,
      minTotalAmount: minValue,
      maxTotalAmount: maxValue,
      remark,
      itemName,
    };
    setPage(1);
    setFilterFeilds(filterFeilds);
  };

  const handleReset = () => {
    setFilterFeilds({
      hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
    });

    setStatusFilter(null);
    setHostel([]);
    setRequestId("");
    setUrgency(null);
    setPaymentStatus(null);
    setRequestStartDate(null);
    setRequestEndDate(null);
    setVendorName([]);
    setHead(null);
    setSubHead(null);
    setSubHeadType(null);
    setPaymentDue(null);
    setCompletionStartRange(null);
    setCompletionEndDateRange(null);
    setRemark("");
    setItemName("");
  };

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/operations/edit/goPurchase/${id}`);
    } else {
      setId(id);
    }
  };

  const handleUpdateGoPurchase = (id) => {
    history.push(`/operations/edit/goPurchase/${id}`);
  };

  useEffect(() => {
    if (vendorData?.getAllCapexVendors) {
      const options = vendorData?.getAllCapexVendors?.map((vendor) => ({
        label: vendor.vendorName,
        value: vendor._id,
      }));
      setVendorNameOption(options);
    }
  }, [vendorData]);
  const HeadList = [{ label: "CAPEX", value: "CAPEX" }];

  let SubHeadList = [];
  if (head?.value === "CAPEX") {
    SubHeadList = [
      { label: "Hostel", value: "Hostel" },
      { label: "F&B", value: "F&B" },
      { label: "Ancillary", value: "Ancillary" },
    ];
  }

  let CapexSubHeadTypeList = [];

  if (head?.value === "CAPEX" && subHead) {
    CapexSubHeadTypeList = [
      { label: "Civil", value: "Civil" },
      { label: "Furniture", value: "Furniture" },
      { label: "Furnishing", value: "Furnishing" },
      { label: "Fabrication", value: "Fabrication" },
      { label: "Electrical", value: "Electrical" },
      { label: "Electronics/Equipments", value: "Electronics/Equipments" },
      { label: "Plumbing", value: "Plumbing" },
      { label: "Others", value: "Others" },
    ];
  }

  const handleSliderChange = (values) => {
    setMinValue(values[0]);
    setMaxValue(values[1]);
  };

  return (
    // localStorage.getItem("modules")?.includes("Operations") &&
    <div>
      <Row>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Hostel</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              id="hostel"
              name="hostel"
              value={hostel}
              closeMenuOnSelect={false}
              options={hostelsList}
              onChange={(value) => setHostel(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="statusFilter">
              <strong>Request status</strong>
            </Label>
            <Select
              isClearable={true}
              id="statusFilter"
              name="statusFilter"
              isMulti
              theme={selectThemeColors}
              options={statusDropDownList}
              value={statusFilter}
              onChange={(value) => setStatusFilter(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="paymentStatus">
              <strong>Payment status</strong>
            </Label>
            <Select
              isClearable={true}
              id="paymentStatus"
              name="paymentStatus"
              // isMulti
              theme={selectThemeColors}
              options={paymentStatusList}
              value={paymentStatus}
              onChange={(value) => setPaymentStatus(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="requestId">
              <strong>Request Id</strong>
            </Label>
            <Input
              type="text"
              value={requestId}
              id="requestId"
              name="requestId"
              //placeholder="Request Id"
              onChange={(e) => setRequestId(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="urgency">
              <strong>Urgency*</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              id="urgency"
              name="urgency"
              options={urgencyList}
              value={urgency}
              onChange={(value) => setUrgency(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Request date range</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={requestStartDate}
              options={{
                mode: "range",
              }}
              onChange={(date) => {
                setRequestStartDate(dateFormatter(date[0]));
                setRequestEndDate(dateFormatter(date[1]));
              }}
              id="checkinDate"
              isClearable={true}
              // placeholder="Request date range"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Completion date range</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={completionStartRange}
              options={{
                mode: "range",
              }}
              onChange={(date) => {
                setCompletionStartRange(dateFormatter(date[0]));
                setCompletionEndDateRange(dateFormatter(date[1]));
              }}
              id="checkinDate"
              isClearable={true}
              // placeholder="Completion date range"
            />
          </FormGroup>
        </Col>

        {/* <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="totalAmount">
              <strong>Total Amount</strong>
            </Label>
            <Input
              type="range"
              value={minAmount}
              id="totalAmount"
              name="totalAmount"
              min="0"
              max="500000"
              onChange={(e) => setMinAmount(e.target.value)}
            />
            <span>{minAmount}</span>
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="totalAmount">
              <strong>Total Amount</strong>
            </Label>
            <Input
              type="range"
              value={maxAmount}
              id="totalAmount"
              name="totalAmount"
              min="0"
              max="500000"
              onChange={(e) => setMaxAmount(e.target.value)}
            />
            <span>{maxAmount}</span>
          </FormGroup>
        </Col> */}

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="vendorNames">
              <strong>Vendors</strong>
            </Label>
            <Select
              isClearable={true}
              id="vendorNames"
              name="vendorNames"
              isMulti
              theme={selectThemeColors}
              options={vendorNameOption}
              value={vendorName}
              onChange={(value) => setVendorName(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="head">
              <strong>Head</strong>
            </Label>
            <Select
              isClearable={true}
              id="head"
              name="head"
              // isMulti
              theme={selectThemeColors}
              options={HeadList}
              value={head}
              onChange={(value) => setHead(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="subHead">
              <strong>Sub-head</strong>
            </Label>
            <Select
              isClearable={true}
              id="subHead"
              name="subHead"
              // isMulti
              theme={selectThemeColors}
              options={SubHeadList}
              value={subHead}
              onChange={(value) => setSubHead(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="dueAmount">
              <strong>Sub-head type</strong>
            </Label>
            <Select
              isClearable={true}
              id="dueAmount"
              name="dueAmount"
              // isMulti
              theme={selectThemeColors}
              options={CapexSubHeadTypeList}
              value={subHeadType}
              onChange={(value) => setSubHeadType(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="paymentDue">
              <strong>Payment due</strong>
            </Label>
            <Select
              isClearable={true}
              id="paymentDue"
              name="paymentDue"
              // isMulti
              theme={selectThemeColors}
              options={paymentDueOptions}
              value={paymentDue}
              onChange={(value) => setPaymentDue(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="remark">
              <strong>Remark</strong>
            </Label>
            <Input
              type="text"
              value={remark}
              id="remark"
              name="remark"
              //placeholder="Remark"
              onChange={(e) => setRemark(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="itemName">
              <strong>Item Name</strong>
            </Label>
            <Input
              type="text"
              value={itemName}
              id="itemName"
              name="itemName"
              //placeholder="Item Name"
              onChange={(e) => setItemName(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <Label className="form-label" for="paymentDue">
            <strong>Total Amount range</strong>
          </Label>
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="slider-thumb"
            trackClassName="slider-track"
            defaultValue={[minValue, maxValue]}
            onChange={handleSliderChange}
            min={1}
            max={500000}
            pearling
            snapDragDisabled
          />
          <div style={{ display: "flex" }}>
            <p>{minValue}</p>
            <p style={{ marginLeft: "auto" }}>{maxValue}</p>
          </div>
        </Col>

        <Col sm="3"></Col>
        <Col className="mb-2">
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={handleSearch}
          >
            Search
          </Button>

          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </Button>

          <Button
            color="success"
            appearance="success"
            type="button"
            style={{
              height: "40px",
              marginTop: "22px",
              marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            onClick={() =>
              getGOPurchaseRequestReportDataHandler(exportInputFilter)
            }
          >
            Export
          </Button>
        </Col>
      </Row>

      {/* {loading ? (
        <h2 style={{ textAlign: "center", marginTop: "110px" }}>
          Please wait as this may take some time...
        </h2>
      ) : tableData.length > 0 ? (
        <> */}
      <GOPurchaseTable
        setId={setId}
        id={id}
        openModal={() => setOpenModal(!openModal)}
        data={tableData}
        handleEdit={handleEdit}
        handleUpdateGoPurchase={handleUpdateGoPurchase}
        count={count}
        consolidatedTotalAmount={consolidatedTotalAmount}
        consolidatedTotalAmountPaid={consolidatedTotalAmountPaid}
        consolidatedTotalTdsAmount={consolidatedTotalTdsAmount}
        consolidatedTotalDeductionAmount={consolidatedTotalDeductionAmount}
        refetch={refetch}
        loading={loading}
      />
      <TablePagination
        setPage={setPage}
        page={page}
        totalPage={totalPage}
        loading={loading}
      />
      {/* </>
      ) : (
        <h2 style={{ textAlign: "center", marginTop: "110px" }}>
          No Data Found
        </h2>
      )} */}

      {!editData?._id && userRole === "Ground Staff" && (
        /* || userRole === "Area Manager" */
        <AddEditModal
          setId={setId}
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default GOPurchase;
