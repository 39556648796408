import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Input, FormGroup, Label, Button, Badge } from "reactstrap";
// import OverviewDetailsButton from "./OverviewDetailsButton";
// import SaveDiscardBtnPurchase from "./save-discard-btn-group";
import { useParams } from "react-router";
import Select from "react-select";
import AttachmentComponent from "../AttachmentComponent";
import toaster from "@components/common/Toaster";
import { Trash, Link } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import confirmDelete from "@components/common/confirmDelete";
import { useHostelList } from "../../../../api/dataSource";
import { useUpdateBlacklistedGuests } from "../../../../api/WebApp/BlacklistedGuests/Mutation";
import { useHistory } from "react-router";
// import { pulse } from "./animation.css";
import AddVideoUrlBtn from "../add-video-url-btn";

const Overview = () => {
  const { id } = useParams();

  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [reservationID, setReservationID] = useState(null);

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [type, setType] = useState(null);
  const [severity, setSeverity] = useState(null);
  const [documentID, setDocumentID] = useState(null);
  const [reason, setReason] = useState(null);
  const [status, setStatus] = useState(null);
  const [attachment, setAttachment] = useState([]);
  const [videoUrl, setVideoUrl] = useState([{ id: uuidv4(), url: "" }]);
  const [approverRemark, setApproverRemark] = useState("");
  const [CXRemark, setCXRemark] = useState("");
  const [logs, setLogs] = useState(null);
  const [dummyState] = useState(null);

  const uploadImageType = [{ label: "mediaURL", value: "supportingEvidence" }];

  //for fetching hostel List
  const { data: allHostelsData } = useHostelList();

  const userName = localStorage.getItem("name");

  const userRole = localStorage.getItem("role");

  const userDepartment = localStorage.getItem("department");

  const userHostel = localStorage.getItem("hostels");

  const state = useSelector((state) => state?.cms);

  const dbData = state?.tableData;

  const dbStatus = state?.tableData?.status;

  const history = useHistory();

  const severityList = [
    { label: "Low", value: "Low" },
    // { label: "Moderate", value: "Moderate" },
    { label: "High", value: "High" },
  ];

  const GS = userRole === "Ground Staff";
  const AM = userRole === "Area Manager";
  const RM = userRole === "Regional Manager";
  const CEO = userRole === "CEO";
  const CX =
    userDepartment === "Customer Experience" ||
    userRole === "Customer Experience";

  const statusList = [
    // (GS || AM || RM || CEO) && { label: "Pending", value: "Pending" },
    GS && { label: "Pending", value: "Pending" },
    (AM || RM || CEO || CX) &&
      dbStatus === "Pending" && { label: "Approved", value: "Approved" },
    (AM || RM || CEO || CX) &&
      dbStatus === "Pending" && { label: "Rejected", value: "Rejected" },
    (AM || RM || CEO || CX) &&
      dbStatus === "Approved" && { label: "Reversed", value: "Reversed" },
  ].filter(Boolean);

  const typeList = [
    { label: "Misconduct", value: "Misconduct" },
    { label: "Payment", value: "Payment" },
    { label: "Policy break", value: "Policy break" },
    { label: "Fraud", value: "Fraud" },
    { label: "Abuse", value: "Abuse" },
  ];

  //update handler
  const [
    updateBlacklistedGuestsHandler,
    { data: updateBlacklistedGuestsData },
  ] = useUpdateBlacklistedGuests();

  //refetching and toggle logic
  useEffect(() => {
    if (updateBlacklistedGuestsData?.updateBlacklistedGuests?._id) {
      // refetch();
      history.push("/webApp/blacklistedGuests");
    }
  }, [updateBlacklistedGuestsData]);

  useEffect(() => {
    const allowedHostels = JSON.parse(userHostel);
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList]);

  const handleAddVideoUrls = () => {
    setVideoUrl([...videoUrl, { id: uuidv4(), url: "" }]);
  };

  const handleRemoveUrls = (id) => {
    // eslint-disable-next-line array-callback-return
    let newVideoUrlData = videoUrl.filter((item) => {
      if (item.id !== id) {
        return true;
      }
    });
    confirmDelete(() => setVideoUrl(newVideoUrlData));
  };

  const handleTextChangeUrl = (val, id, key) => {
    setVideoUrl(
      videoUrl.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );
  };

  //setting edit data value
  useEffect(() => {
    if (dbData?._id) {
      setHostel(
        dbData?.hostel?.name
          ? { label: dbData?.hostel?.name, value: dbData?.hostel?._id }
          : null
      );

      setReservationID(dbData?.reservationID);
      setName(dbData?.name);
      setEmail(dbData?.email);
      setMobile(dbData?.mobile);
      setType(
        dbData?.type !== null
          ? { label: dbData?.type, value: dbData?.type }
          : null
      );
      setSeverity(
        dbData?.severity !== null
          ? { label: dbData?.severity, value: dbData?.severity }
          : null
      );
      setDocumentID(dbData?.documentID);
      setReason(dbData?.reason);
      setStatus(
        dbData?.status !== null
          ? { label: dbData?.status, value: dbData?.status }
          : null
      );

      if (GS) {
        setStatus(
          dbData?.status !== null
            ? dbData?.status === "Pending" && {
                label: dbData?.status,
                value: dbData?.status,
              }
            : null
        );
      }

      if (AM || RM || CEO || CX) {
        setStatus(
          dbData?.status !== null
            ? (dbData?.status === "Approved" ||
                dbData?.status === "Rejected") && {
                label: dbData?.status,
                value: dbData?.status,
              }
            : null
        );
      }
      if (AM || RM || CEO || CX) {
        setStatus(
          dbData?.status !== null
            ? dbData?.status === "Reversed" && {
                label: dbData?.status,
                value: dbData?.status,
              }
            : null
        );
      }
      setAttachment(
        dbData?.supportingEvidence !== null ? dbData?.supportingEvidence : []
      );

      setVideoUrl(
        dbData?.videoUrl?.map((item) => {
          return {
            id: uuidv4(),
            url: item,
          };
        })
      );
      setApproverRemark(
        dbData?.approverRemark !== null ? dbData?.approverRemark : ""
      );
      setCXRemark(dbData?.CXRemark !== null ? dbData?.CXRemark : "");
    } else {
      setHostelsList([]);
      setHostel(null);
      setReservationID(null);
      setName(null);
      setEmail(null);
      setMobile(null);
      setType(null);
      setSeverity(null);
      setDocumentID(null);
      setReason(null);
      setStatus(null);
      setAttachment([]);
      setVideoUrl([{ id: uuidv4(), url: "" }]);
      setApproverRemark("");
      setCXRemark("");
      setLogs(null);
    }
  }, [dbData]);

  //submit handler for form
  const OverviewDetailsButton = () => {
    let videoUrlClone = JSON.parse(JSON.stringify(videoUrl));
    videoUrlClone.forEach((item) => {
      delete item.id;
    });
    const videoUrlData = videoUrlClone?.map((item) => item?.url);
    //! below filter to remove "" from []
    // ?.filter((item) => item !== "");

    const data = {
      hostel: hostel?.value,
      reservationID,
      name,
      email,
      mobile,
      type: type?.value,
      severity: severity?.value,
      documentID,
      reason,
      supportingEvidence: attachment,
      videoUrl: videoUrlData,
      status: status?.value,
      // approverRemark,
      // CXRemark,
    };

    if (GS) {
      if (
        !data?.hostel ||
        !data?.reservationID ||
        !data?.name ||
        !data?.email ||
        !data?.mobile ||
        !data?.type ||
        !data?.severity ||
        !data?.reason ||
        !data?.supportingEvidence?.length
        // || !data?.videoUrl?.length
        // || !data?.status
      ) {
        toaster(
          "error",
          "Please fill all the required details marked with '*'"
        );
        return;
      }

      updateBlacklistedGuestsHandler(id, {
        ...data,
      });
      toaster("success", "Blacklisted guest updated successfully");
    }

    if ((AM || RM || CEO || CX) && dbStatus === "Pending") {
      if (status?.value !== "Pending" && !approverRemark) {
        toaster(
          "error",
          "Please add the approverRemark and change the status '*'"
        );
        return;
      } else if (status?.value === "Approved" || status?.value === "Rejected") {
        updateBlacklistedGuestsHandler(id, {
          ...data,
          approverRemark,
        });
        toaster("success", "Blacklisted guest updated successfully");
      }
    }
    if ((AM || RM || CEO || CX) && dbStatus === "Approved") {
      if (status?.value !== "Reversed" && !CXRemark) {
        toaster("error", "Please add the remark and change the status '*'");
        return;
      } else if (status?.value === "Reversed") {
        updateBlacklistedGuestsHandler(id, {
          ...data,
          CXRemark,
        });
        toaster("success", "Blacklisted guest updated successfully");
      }
    }

    setHostelsList([]);
    setHostel(null);
    setReservationID(null);
    setName(null);
    setEmail(null);
    setMobile(null);
    setType(null);
    setSeverity(null);
    setDocumentID(null);
    setReason(null);
    setStatus(null);
    setLogs(null);
  };

  return (
    <div>
      <Row>
        {/* Hostel Selection */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="hostel">
              Hostel*
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="hostel"
              name="hostel"
              options={hostelsList}
              value={hostel}
              onChange={(value) => setHostel(value)}
              classNamePrefix="select"
              isDisabled={GS || AM || RM || CEO || CX}
            />
          </FormGroup>
        </Col>

        {/* Reservation ID */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="reservationID">
              Reservation ID*
            </Label>
            <Input
              type="text"
              value={reservationID}
              id="reservationID"
              name="reservationID"
              placeholder="Reservation ID"
              onChange={(e) => setReservationID(e.target.value)}
              disabled={GS || AM || RM || CEO || CX}
            />
          </FormGroup>
        </Col>

        {/* Name Field */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="name">
              Name*
            </Label>
            <Input
              type="text"
              value={name}
              id="name"
              name="name"
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
              disabled={GS || AM || RM || CEO || CX}
            />
          </FormGroup>
        </Col>

        {/* Email Field */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="email">
              Email ID*
            </Label>
            <Input
              type="text"
              value={email}
              id="email"
              name="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              disabled={GS || AM || RM || CEO || CX}
            />
          </FormGroup>
        </Col>

        {/* Mobile Field */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="mobile">
              Mobile number*
            </Label>
            <Input
              type="text"
              value={mobile}
              id="mobile"
              name="mobile"
              placeholder="Phone number"
              onChange={(e) => setMobile(e.target.value)}
              disabled={GS || AM || RM || CEO || CX}
            />
          </FormGroup>
        </Col>

        {/* Type Field */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="type">
              Type*
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="type"
              name="type"
              options={typeList}
              value={type}
              onChange={(value) => setType(value)}
              classNamePrefix="select"
              isDisabled={GS || AM || RM || CEO || CX}
            />
          </FormGroup>
        </Col>

        {/* Severity Field */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="severity">
              Severity*
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="severity"
              name="severity"
              options={severityList}
              value={severity}
              onChange={(value) => setSeverity(value)}
              classNamePrefix="select"
              // isDisabled={GS || AM || RM || CEO || CX}
              isDisabled={GS || AM || RM || CEO || CX}
            />
          </FormGroup>
        </Col>

        {/* Document ID */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="documentID">
              Document ID
            </Label>
            <Input
              type="text"
              value={documentID}
              id="documentID"
              name="documentID"
              placeholder="Document ID"
              onChange={(e) => setDocumentID(e.target.value)}
              disabled={GS || AM || RM || CEO || CX}
            />
          </FormGroup>
        </Col>

        {/* Reason Text Area */}
        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="reason">
              Reason*
            </Label>
            <Input
              type="textarea"
              value={reason}
              id="reason"
              name="reason"
              placeholder="Reason"
              rows="1"
              onChange={(e) => setReason(e.target.value)}
              disabled={GS || AM || RM || CEO || CX}
            />
          </FormGroup>
        </Col>

        {/* Status */}
        {((GS && dbStatus === "Pending") ||
          ((AM || RM || CEO || CX) &&
            (dbStatus !== "Reversed" || dbStatus === "Approved"))) && (
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="status">
                Status*
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                id="status"
                name="status"
                options={statusList}
                value={status}
                onChange={(value) => setStatus(value)}
                classNamePrefix="select"
                isDisabled={
                  (GS && dbStatus?.length) ||
                  // ((AM || RM || CEO || CX) && dbStatus !== "Pending") ||
                  // dbStatus === "Pending" ||
                  // ((AM || RM || CEO) && dbStatus === "Reversed")
                  dbStatus === "Reversed" ||
                  dbStatus === "Rejected"
                }
              />
            </FormGroup>
          </Col>
        )}

        {/* Approver Remark */}
        {(AM || RM || CEO || CX) && dbStatus !== "Reversed" && (
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="approverRemark">
                Approver remark*
              </Label>
              <Input
                type="textarea"
                value={approverRemark}
                id="approverRemark"
                name="approverRemark"
                placeholder="Approver Remark"
                rows="1"
                onChange={(e) => setApproverRemark(e.target.value)}
                // disabled={(AM || RM || CEO) && dbStatus === "Reversed"}
                // disabled={dbStatus === "Reversed" || dbStatus === "Rejected"}
                disabled={dbStatus !== "Pending"}
              />
            </FormGroup>
          </Col>
        )}

        {/* CX Remark */}
        {(AM || RM || CEO || CX) && dbStatus === "Approved" && (
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="CXRemark">
                Remark*
              </Label>
              <Input
                type="textarea"
                value={CXRemark}
                id="CXRemark"
                name="CXRemark"
                placeholder="Remark"
                rows="1"
                onChange={(e) => setCXRemark(e.target.value)}
                disabled={dbStatus === "Reversed" || dbStatus === "Rejected"}
              />
            </FormGroup>
          </Col>
        )}

        {/* Supporting Evidence Attachment */}
        <Col sm="12">
          <Label className="form-label" for="attachment">
            Supporting evidence* (Max file size: 2MB)
          </Label>
          <AttachmentComponent
            imageList={attachment}
            setImageList={setAttachment}
            editData={dummyState}
            uploadType={uploadImageType}
            user={GS && dbStatus?.length === 0}
          />
        </Col>

        {/* Video URLs Section */}
        <Col sm="12">
          {videoUrl.map(({ id, url }, index) => (
            <React.Fragment key={id}>
              <FormGroup row className="align-items-center">
                {/* Label for Video URL */}
                <Label sm="12" className="mb-2">
                  Video URL {index + 1}
                </Label>

                {/* URL Input and Open Button */}
                <Col sm="11">
                  <div className="d-flex">
                    <Input
                      type="text"
                      value={url}
                      name="url"
                      placeholder="Video URL"
                      onChange={(e) =>
                        handleTextChangeUrl(e.target.value, id, "url")
                      }
                      style={{
                        borderTopRightRadius: "0",
                        borderBottomRightRadius: "0",
                      }}
                      disabled={GS || AM || RM || CEO || CX}
                    />
                    <Button
                      color="light"
                      type="button"
                      size="sm"
                      onClick={() => url?.length && window.open(url, "_blank")}
                      style={{
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                      }}
                      className="d-flex align-items-center"
                    >
                      <Link size={16} />
                    </Button>
                  </div>
                </Col>

                {/* Remove Button */}
                {GS && dbStatus?.length === 0 && (
                  <Col sm="1" className="d-flex justify-content-center">
                    {videoUrl.length > 1 && (
                      <Button
                        className="btn-icon rounded-circle"
                        color="danger"
                        size="sm"
                        onClick={() => handleRemoveUrls(id)}
                        // disabled={dbStatus?.length}
                      >
                        <Trash size={14} />
                      </Button>
                    )}
                  </Col>
                )}
              </FormGroup>
            </React.Fragment>
          ))}

          {/* Add Video URL Button */}
          <AddVideoUrlBtn
            addAction={handleAddVideoUrls}
            user={GS}
            dbStatus={dbStatus}
          />
        </Col>

        <hr
          style={{
            borderTop: "2px solid #ffe700",
            width: "100%",
          }}
        />
        {((GS && dbStatus === "Pending") ||
          ((AM || RM || CEO || CX) &&
            (dbStatus !== "Reversed" || dbStatus === "Approved"))) && (
          <Col sm="12" style={{ textAlign: "right" }}>
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={OverviewDetailsButton}
              disabled={
                (GS && dbStatus === "Pending") ||
                dbStatus === "Reversed" ||
                dbStatus === "Rejected"
                // || ((AM || RM || CEO || CX) && dbStatus === "Approved")
              }
            >
              Save
            </Button>
          </Col>
        )}

        <Col sm="12">
          {/* <hr/> */}
          <FormGroup className="w-100 mt-2">
            {dbData?._id && (
              <Label className="form-label" for="logs">
                <strong>Logs:</strong>
              </Label>
            )}
            <br />
            {dbData?.logs
              ?.map((log, i) => {
                return (
                  <React.Fragment key={i}>
                    <Label className="form-label" for="name">
                      <li>{log}.</li>
                    </Label>
                    <br />
                  </React.Fragment>
                );
              })
              .reverse()}
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default Overview;
