export const formatDate = (date) => {
  const options = { weekday: "short", day: "2-digit", month: "short" };
  return date.toLocaleDateString("en-US", options);
};

export const formatDateToDDMMYYYY = (dateString) => {
  const date = new Date(dateString);
  const year = new Date().getFullYear();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return `${day}-${month}-${year}`;
};

export const generateDateRange = (startDate, endDate = null, interval = 7) => {
  const dateArray = [];
  const currentDate = new Date(startDate);

  if (!endDate) {
    // Generate the next 7 dates if no end date is provided
    for (let i = 0; i < interval; i++) {
      dateArray.push(new Date(currentDate).toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  } else {
    // Generate the date range between startDate and endDate
    const finalDate = new Date(endDate);
    while (currentDate <= finalDate) {
      dateArray.push(new Date(currentDate).toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  return dateArray;
};

export function generateFormattedDates(startDate, interval) {
  const dates = [];
  let currentDate = new Date(startDate);

  for (let i = 0; i < interval; i++) {
    dates.push(formatDate(currentDate));
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  return dates;
}
