import React, { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Table } from "reactstrap";
import { v4 as uuidv4 } from "uuid";

const tableHeaderData = [
  "Hostel Name",
  "Room",
  "Sold Nights",
  "Revenue",
  "ADR",
];

const RoomwiseADRReportTable = ({ openModal, setId, data, refetch }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2>Room-wise ADR Report</h2>
              </div>
            </CardTitle>
          </CardHeader>
          <Table responsive hover className="table-bordered">
            <thead className="bg-light">
              <tr>
                {tableHeaderData.map((item, index) => (
                  <th key={index}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <>
                  {item?.salesData?.map((roomData, index) => (
                    <tr key={uuidv4()}>
                      
                      {index === 0 && (
                        <td rowSpan={item?.salesData.length}>
                          <h5>{item?.hostelName}</h5>
                        </td>
                      )}
                      <td>{roomData?.Room || "-"}</td>
                      <td>
                        {roomData?.TSNights !== null ? roomData?.TSNights : "-"}
                      </td>
                      <td>
                        {roomData?.Revenue !== null
                          ? roomData?.Revenue.toFixed(2)
                          : "-"}
                      </td>
                      <td>
                        {roomData?.ADR !== null
                          ? roomData?.ADR.toFixed(2)
                          : "-"}
                      </td>
                    </tr>
                  ))}
                </>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default RoomwiseADRReportTable;

