import { Globe, Circle } from "react-feather";

const role = localStorage.getItem("role");
const modules = JSON.parse(localStorage.getItem("modules"));

const filterModules = (role, modules) => {
  try {
    // if (role === "Super Admin") {
    //   return [
    //     {
    //       id: "webAppRequests",
    //       title: "Master Request Configuration",
    //       // icon: <Circle size={12} />,
    //       navLink: "/webApp/request",
    //     },
    //     {
    //       id: "webAppHostelRequests",
    //       title: "Hostelwise Request Configuration",
    //       // icon: <Circle size={12} />,
    //       navLink: "/webApp/hostelRequest",
    //     },
    //     {
    //       id: "webAppEventsAndActivities",
    //       title: "Hostelwise Curated Events ",
    //       // icon: <Circle size={12} />,
    //       navLink: "/webApp/events&activities",
    //     },
    //     {
    //       id: "ServiceConfig",
    //       title: "Hostelwise Add-ons Configuration",
    //       // icon: <Circle size={12} />,
    //       navLink: "/webApp/serviceConfig",
    //     },
    //     {
    //       id: "webCheckin",
    //       title: "Web Check In CMS",
    //       // icon: <Circle size={12} />,
    //       navLink: "/webApp/webCheckin",
    //     },
    //     {
    //       id: "ContactlessCheckout",
    //       title: "Web Check Out CMS",
    //       // icon: <Circle size={12} />,
    //       navLink: "/webApp/contactlessCheckout",
    //     },
    //     {
    //       id: "webAppUserRequest",
    //       title: "Guest Requests CMS",
    //       // icon: <Circle size={12} />,
    //       navLink: "/webApp/userRequest",
    //     },

    //     // {
    //     //   id: "addOn",
    //     //   title: "Add On",
    //     //   icon: <Circle size={12} />,
    //     //   navLink: "/webApp/addOn",
    //     // },

    //     {
    //       id: "AddonBooking",
    //       title: "Services CMS",
    //       // icon: <Circle size={12} />,
    //       navLink: "/webApp/AddonBooking",
    //     },
    //     {
    //       id: "EventBookings",
    //       title: "Events & Activities CMS",
    //       // icon: <Circle size={12} />,
    //       navLink: "/webApp/eventBookings",
    //     },
    //     {
    //       id: "GluBanner",
    //       title: "Glu Banner",
    //       // icon: <Circle size={12} />,
    //       navLink: "/webApp/glubanner",
    //     },
    //   ];
    // }
    if (
      modules?.includes("Glu Configuration") &&
      modules?.includes("Glu Reports")
    ) {
      return [
        {
          id: "webAppRequests",
          title: "Master Request Configuration",
          icon: <Circle size={12} />,
          navLink: "/webApp/request",
        },
        {
          id: "webAppHostelRequests",
          title: "Hostelwise Request Configuration",
          icon: <Circle size={12} />,
          navLink: "/webApp/hostelRequest",
        },
        {
          id: "webAppEventsAndActivities",
          title: "Hostelwise Curated Events ",
          icon: <Circle size={12} />,
          navLink: "/webApp/events&activities",
        },
        {
          id: "hostelRooms",
          title: "Hostel Room Config ",
          icon: <Circle size={12} />,
          navLink: "/webApp/hostelRooms",
        },
        {
          id: "ServiceConfig",
          title: "Hostelwise Add-ons Configuration",
          icon: <Circle size={12} />,
          navLink: "/webApp/serviceConfig",
        },
        {
          id: "webCheckin",
          title: "Web Check In CMS",
          icon: <Circle size={12} />,
          navLink: "/webApp/webCheckin",
        },
        {
          id: "ContactlessCheckout",
          title: "Web Check Out CMS",
          icon: <Circle size={12} />,
          navLink: "/webApp/contactlessCheckout",
        },
        {
          id: "webAppUserRequest",
          title: "Guest Requests CMS",
          icon: <Circle size={12} />,
          navLink: "/webApp/userRequest",
        },
        {
          id: "AddonBooking",
          title: "Services CMS",
          icon: <Circle size={12} />,
          navLink: "/webApp/AddonBooking",
        },
        {
          id: "EventBookings",
          title: "Events & Activities CMS",
          icon: <Circle size={12} />,
          navLink: "/webApp/eventBookings",
        },
        // {
        //   id: "addOn",
        //   title: "Add On",
        //   icon: <Circle size={12} />,
        //   navLink: "/webApp/addOn",
        // },

        {
          id: "GluBanner",
          title: "Glu Banner",
          icon: <Circle size={12} />,
          navLink: "/webApp/glubanner",
        },
        {
          id: "hostelWiseRevenue",
          title: "Hostel Wise Revenue",
          icon: <Circle size={12} />,
          navLink: "/webApp/hostelWiseRevenue",
        },
        {
          id: "categoryWiseRevenue",
          title: "Category Wise Revenue",
          icon: <Circle size={12} />,
          navLink: "/webApp/categoryWiseRevenue",
        },
        {
          id: "itemWiseRevenue",
          title: "Item Wise Revenue",
          icon: <Circle size={12} />,
          navLink: "/webApp/itemWiseRevenue",
        },
        // {
        //   id: "UnboxMasterMenu",
        //   title: "Unbox MasterMenu",
        //   icon: <Circle size={12} />,
        //   navLink: "/webApp/unboxmastermenu",
        // },
      ];
    } else if (modules?.includes("Glu Configuration")) {
      return [
        {
          id: "webAppRequests",
          title: "Master Request Configuration",
          icon: <Circle size={12} />,
          navLink: "/webApp/request",
        },
        {
          id: "webAppHostelRequests",
          title: "Hostelwise Request Configuration",
          icon: <Circle size={12} />,
          navLink: "/webApp/hostelRequest",
        },
        {
          id: "webAppEventsAndActivities",
          title: "Hostelwise Curated Events ",
          icon: <Circle size={12} />,
          navLink: "/webApp/events&activities",
        },
        {
          id: "hostelRooms",
          title: "Hostel Room Config ",
          icon: <Circle size={12} />,
          navLink: "/webApp/hostelRooms",
        },
        {
          id: "ServiceConfig",
          title: "Hostelwise Add-ons Configuration",
          icon: <Circle size={12} />,
          navLink: "/webApp/serviceConfig",
        },
        // {
        //   id: "AddonBooking",
        //   title: "Services CMS",
        //   icon: <Circle size={12} />,
        //   navLink: "/webApp/AddonBooking",
        // },
        {
          id: "GluBanner",
          title: "Glu Banner",
          icon: <Circle size={12} />,
          navLink: "/webApp/glubanner",
        },
        {
          id: "hostelWiseRevenue",
          title: "Hostel Wise Revenue",
          icon: <Circle size={12} />,
          navLink: "/webApp/hostelWiseRevenue",
        },
        {
          id: "categoryWiseRevenue",
          title: "Category Wise Revenue",
          icon: <Circle size={12} />,
          navLink: "/webApp/categoryWiseRevenue",
        },
        // {
        //   id: "UnboxMasterMenu",
        //   title: "Unbox MasterMenu",
        //   icon: <Circle size={12} />,
        //   navLink: "/webApp/unboxmastermenu",
        // },
      ];
    } else if (modules?.includes("Glu Reports")) {
      return [
        {
          id: "webAppUserRequest",
          title: "Guest Requests CMS",
          icon: <Circle size={12} />,
          navLink: "/webApp/userRequest",
        },
        // {
        //   id: "addOn",
        //   title: "Add On",
        //   icon: <Circle size={12} />,
        //   navLink: "/webApp/addOn",
        // },
        // {
        //   id: "ServiceConfig",
        //   title: "Hostelwise Add-ons Configuration",
        //   icon: <Circle size={12} />,
        //   navLink: "/webApp/serviceConfig",
        // },
        {
          id: "webCheckin",
          title: "Web Check In CMS",
          icon: <Circle size={12} />,
          navLink: "/webApp/webCheckin",
        },
        {
          id: "ContactlessCheckout",
          title: "Web Check Out CMS",
          icon: <Circle size={12} />,
          navLink: "/webApp/contactlessCheckout",
        },
        // {
        //   id: "AddonBooking",
        //   title: "Add-On Bookings",
        //   icon: <Circle size={12} />,
        //   navLink: "/webApp/AddonBooking",
        // },
        {
          id: "AddonBooking",
          title: "Services CMS",
          icon: <Circle size={12} />,
          navLink: "/webApp/AddonBooking",
        },
        {
          id: "EventBookings",
          title: "Events & Activities CMS",
          icon: <Circle size={12} />,
          navLink: "/webApp/eventBookings",
        },
        // {
        //   id: "BlacklistedGuests",
        //   title: "Blacklisted Guests CMS",
        //   icon: <Circle size={12} />,
        //   navLink: "/webApp/blacklistedGuests",
        // },
      ];
    }
  } catch (error) {
    console.error(error);
  }
};

export default // role === "Super Admin" ||
modules?.includes("Glu Configuration") || modules?.includes("Glu Reports")
  ? [
      {
        id: "webApp",
        title: "GLU",
        icon: <Globe size={20} />,
        badge: "light-warning",
        children:
          filterModules(role, modules)?.length > 0
            ? filterModules(role, modules)
            : null,
      },
    ]
  : [];
