import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { startLoading, stopLoading } from "@store/loader/loader.action";
import { useDispatch } from "react-redux";

import {
  CHECK_COMPETITOR_EXISTS,
  GET_ALL_HOSTEL_COMPARISONS,
  GET_PAGINATED_HOSTEL_COMPARISONS,
} from "./Queries";

import { GET_HOSTELS_NAME_AND_ID } from "../List/Queries";

import { CREATE_HOSTEL_COMPARISON, UPDATE_HOSTEL_COMPARISON } from "./Mutation";

export const useLoadingHandler = (loading) => {
  const dispatch = useDispatch();

  useEffect(() => {
    loading ? dispatch(startLoading()) : dispatch(stopLoading());
  }, [dispatch, loading]);
};

export const useGetHostelsNameandId = () => {
  const { loading, error, data, refetch } = useQuery(GET_HOSTELS_NAME_AND_ID);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const useGetPaginatedHostelComparisons = (filter) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_HOSTEL_COMPARISONS,
    {
      variables: {
        filter,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useGetAllHostelComparisons = () => {
  const { loading, error, data, refetch } = useQuery(
    GET_ALL_HOSTEL_COMPARISONS
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const useCreateHostelComparison = () => {
  const [createHostelComparison, { loading, error, data, refetch }] =
    useMutation(CREATE_HOSTEL_COMPARISON);
  useLoadingHandler(loading);

  const createHostelComparisonHandler = (input) => {
    createHostelComparison({
      variables: { input },
    });
  };

  return [createHostelComparisonHandler, { loading, error, data, refetch }];
};

export const useUpdateHostelComparison = () => {
  const [updateHostelComparison, { loading, error, data, refetch }] =
    useMutation(UPDATE_HOSTEL_COMPARISON);
  useLoadingHandler(loading);

  const updateHostelComparisonHandler = (id, input) => {
    updateHostelComparison({
      variables: { id, input },
    });
  };

  return [updateHostelComparisonHandler, { loading, error, data, refetch }];
};

//Added by Ashish
export const useCheckCompetitorExists = (hostel, competitor) => {
  const [CheckCompetitorExists, { loading, error, data }] = useLazyQuery(
    CHECK_COMPETITOR_EXISTS,
    {
      variables: {
        hostel,
        competitor,
      },
      fetchPolicy: "no-cache", // Disable caching as if the user reloads the page, the query will run again
    }
  );

  useLoadingHandler(loading);

  return { CheckCompetitorExists, loading, error, data };
};
