import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  useCheckCompetitorExists,
  useCreateHostelComparison,
  useGetHostelsNameandId,
  useUpdateHostelComparison,
} from "../../api/HostelComparisons/HostelComparisonsDataSource";
import Select from "react-select";
import toaster from "../../@core/components/common/Toaster";
import styled from "styled-components";

const AddEditModal = ({ open, handleToggle, editData, refetch, large }) => {
  console.log(editData, "editData");
  const [hostel, setHostel] = useState(null);
  const [HostelsList, setHostelsList] = useState([]);
  const [platform, setPlatform] = useState(null);
  const [competitor, setCompetitor] = useState(null);
  const [url, setUrl] = useState("");
  const [weightage, setWeightage] = useState(null);
  const [errors, setErrors] = useState({});

  const { data: allHostelsData } = useGetHostelsNameandId();
  const { CheckCompetitorExists, loading, data } = useCheckCompetitorExists({
    hostel: hostel?.value,
    competitor: "The Hosteller",
  });

  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    let HostelsList = allHostelsData?.getHostelsNameandId?.map((hostel) => { 
      return { label: hostel?.name, value: hostel?._id };
    });
    setHostelsList(HostelsList);
  }, [allHostelsData]);

  const [createHostelComparisonHandler, { input }] =
    useCreateHostelComparison();

  const [updateHostelComparisonHandler, { data: UpdatedData }] =
    useUpdateHostelComparison();

  useEffect(() => {
    if (input) {
      refetch();
    }
  }, [input, refetch]);

  useEffect(() => {
    if (UpdatedData) {
      refetch();
    }
  }, [UpdatedData, refetch]);

  useEffect(() => {
    setErrors({});
  }, [open]);

  const PlatformDropdown = [
    { label: "Booking.com", value: "Booking.com" },
    { label: "Goibibo", value: "Goibibo" },
    { label: "MakeMyTrip", value: "MakeMyTrip" },
  ];

  const CompetitorDropdown = [
    { label: "The Hosteller", value: "The Hosteller" },
    { label: "Zostel", value: "Zostel" },
    { label: "GoStops", value: "GoStops" },
  ];

  const resetState = () => {
    setHostel(null);
    setPlatform(null);
    setCompetitor("");
    setUrl("");
    setWeightage(null);
    setButtonDisabled(false);
    setErrors({});
  }

  const handleHostelChange = (value) => {
    setHostel(value);
    setCompetitor(""); // Reset competitor
    setErrors({}); // Reset errors
    setButtonDisabled(false); // Reset button state
  };

  useEffect(() => {
    if (editData) {
      setHostel(
        editData?.hostel?.name
          ? { label: editData?.hostel?.name, value: editData?.hostel?._id }
          : null
      );
      setPlatform(editData.platform);
      setCompetitor(editData.competitor);
      setUrl(editData.url);
      setWeightage(editData.weightage);
    } else {
      resetState();
    }

    return () => {
      resetState();
    }
  }, [editData]);

  useEffect(() => {
    if (buttonDisabled && competitor === "The Hosteller") {
      setErrors({}); // Reset errors
      setButtonDisabled(false); // Reset button state
    }
  }, [buttonDisabled, competitor]);

  useEffect(() => {
    if (hostel?.value && platform) {
      CheckCompetitorExists({
        variables: { hostel: hostel.value, competitor: "The Hosteller" },
      }).then((res) => {
        if (!res?.data?.checkIfCompetitorExistsForHostel) {
          setErrors({
            competitor: "The Hosteller is not added. Please add it first.",
          });
          setButtonDisabled(true);
        } else {
          setErrors((prev) => ({ ...prev, competitor: null })); // Clear errors if exists
          setButtonDisabled(false); // Enable button if competitor exists
        }
      });
    }
  }, [hostel, platform]);

  const handleSubmit = () => {
    if (buttonDisabled)
      return toaster(
        "error",
        "The Hosteller is not added. Please add it first."
      );
    let formIsValid = true;
    const errors = {};

    if (!hostel) {
      // errors.hostel = "Hostel Name is required.";
      toaster("error", "Hostel Name is required.");
      return;
      formIsValid = false;
    }

    if (!platform) {
      // errors.platform = "Platform is required.";
      toaster("error", "Platform is required.");
      return;
      formIsValid = false;
    }

    if (!competitor) {
      // errors.competitor = "Competitor is required.";
      toaster("error", "Competitor is required.");
      return;
      formIsValid = false;
    }

    if (!url) {
      // errors.url = "URL is required.";
      toaster("error", "URL is required.");
      return;
      formIsValid = false;
    }

    if (!weightage) {
      // errors.weightage = "Weightage is required.";
      toaster("error", "Weightage is required.");
      formIsValid = false;
    }

    setErrors(errors);

    if (formIsValid) {
      const input = {
        hostel: hostel?.value,
        platform: platform,
        competitor: competitor,
        url: url,
        weightage: Number(weightage),
      };
      if (editData) {
        updateHostelComparisonHandler(editData._id, input);
        toaster("success", "Entry updated successfully");
      } else {
        createHostelComparisonHandler(input);
        toaster("success", "Entry added successfully");
      }
      refetch();
      handleToggle();
    }
  };

  return (
    <StyledModalWrap
      unmountOnClose={true}
      isOpen={open}
      toggle={handleToggle}
      // backdrop="static"
      className={
        large
          ? "ml-auto m-0 modal-lg position-relative"
          : "ml-auto m-0 position-relative"
      }
    >
      <ModalHeader toggle={handleToggle}>
        {editData ? "Edit Entry" : "Add New Entry"}
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="hostelId">Hostel Name*</Label>
          <Select
            isClearable={false}
            isSearchable={true}
            id="hostel"
            name="hostel"
            options={HostelsList}
            value={hostel}
            onChange={(selectedOption) => handleHostelChange(selectedOption)}
            classNamePrefix="select"
          />
          {errors.hostel && (
            <span className="text-danger">{errors.hostel}</span>
          )}
        </FormGroup>

        <FormGroup>
          <Label for="platform">Platform*</Label>
          <Select
            id="platform"
            name="platform"
            value={PlatformDropdown?.find(
              (option) => option.value === platform
            )}
            options={PlatformDropdown}
            onChange={(selectedOption) => setPlatform(selectedOption.value)}
            classNamePrefix="select"
          />
          {errors.platform && (
            <span className="text-danger">{errors.platform}</span>
          )}
        </FormGroup>

        {/* <FormGroup>
          <Label for="competitor">Competitor*</Label>
          <Select
            id="competitor"
            name="competitor"
            value={CompetitorDropdown?.find(
              (option) => option.value === competitor
            )}
            options={CompetitorDropdown}
            onChange={(selectedOption) => setCompetitor(selectedOption.value)}
            classNamePrefix="select"
          />
          {errors.competitor && (
            <span className="text-danger">{errors.competitor}</span>
          )}
        </FormGroup> */}
        <FormGroup>
          <Label for="competitor">Competitor*</Label>
          <Input
            type="text"
            name="competitor"
            id="competitor"
            value={competitor}
            onChange={(e) => setCompetitor(e.target.value)}
          />
          <span style={{ fontSize: "11px" }} className="text-muted">
            (e.g : The Hosteller)
            <t />
            <br />
          </span>
          {errors.competitor && (
            <span className="text-danger">{errors.competitor}</span>
          )}
        </FormGroup>

        <FormGroup>
          <Label for="url">URL*</Label>
          <Input
            type="text"
            name="url"
            id="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <span style={{ fontSize: "11px" }} className="text-muted">
            (e.g :
            https://www.booking.com/hotel/in/the-hosteller-new-delhi.en-gb.html)
            <t />
            <br />
          </span>
          {errors.url && <span className="text-danger">{errors.url}</span>}
        </FormGroup>
        <FormGroup>
          <Label for="url">Weightage*</Label>
          <Input
            type="number"
            name="weightage"
            id="weightage"
            value={weightage}
            onChange={(e) => setWeightage(e.target.value)}
          />
          {errors.weightage && (
            <span className="text-danger">{errors.weightage}</span>
          )}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        {/* <Button color="secondary" onClick={handleToggle}>
          Cancel
        </Button>*/}
        <div>
          <Button.Ripple
            type="button"
            onClick={handleSubmit}
            color="light"
            block
          >
            {editData ? "Update" : "Create"}
          </Button.Ripple>
        </div>
      </ModalFooter>
    </StyledModalWrap>
  );
};

const StyledModalWrap = styled(Modal)`
  .modal-content {
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
  }
  .modal-header {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    .close {
      margin: 0;
    }
  }
  .modal-footer {
    position: sticky;
    bottom: 0;
    z-index: 99;
    background-color: #f8f8f8;
  }
`;

export default AddEditModal;
