import React, { useEffect, useState } from "react";
import { Edit, FileText, Image } from "react-feather";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Table,
  Row,
  Badge,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { dateFormatHandler } from "../../../utility/Utils";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  // "",
  "Hostel",
  "Reservation Id",
  "Guest Name",
  "Email",
  "Mobile",
  "Document Id",
  "Status",
  "Severity",
  "Type",
  "Reason",
  "Supporting Evidence",
];

const BlacklistedGuestsTable = ({
  openModal,
  setId,
  data,
  handleUpdateBlacklistedGuests,
  refetch,
  loading,
  count,
}) => {
  const [tableData, setTableData] = useState(data);

  const userRole = localStorage.getItem("role");

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  };
  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  const tableDataStyle = {
    minWidth: "240px",
    justifyContent: "center",
    alignItems: "center",
  };

  const handleImagePopup = (images) => {
    if (Array.isArray(images) && images.length > 0) {
      images.forEach((image) => {
        window.open(
          image.mediaURL,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=200,width=600,height=400"
        );
      });
    } else if (typeof images === "string") {
      window.open(
        images,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=200,width=600,height=400"
      );
    }
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <div>
                <h2>Blacklisted Guests (Count: {!count ? 0 : count})</h2>
              </div>
            </CardTitle>

            <CardHeader className="d-flex justify-content-between align-items-center px-3">
              {userRole === "Ground Staff" && (
                <Button.Ripple
                  color="light"
                  onClick={(e) => {
                    e.preventDefault();
                    openModal();
                  }}
                >
                  Add Blacklisted Guest
                </Button.Ripple>
              )}
            </CardHeader>
          </CardHeader>
          {loading ? (
            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
              Please wait...
            </h2>
          ) : tableData.length > 0 ? (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    {tableHeaderData.map((item) => {
                      return (
                        <th style={{ whiteSpace: "nowrap" }} key={uuidv4()}>
                          {item}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((element, i) => (
                    <tr key={uuidv4()}>
                      {/* <td style={{ width: "10px" }}>
                        <Button
                          className="rounded-circle btn-icon"
                          color="none"
                          onClick={(e) => {
                            e.preventDefault();
                            setId(element._id);
                            openModal();
                          }}
                        >
                          <Edit
                            color={reactFeatherIcon.iconColor}
                            size={reactFeatherIcon.iconSize}
                          />
                        </Button>
                      </td> */}
                      <td style={{ width: "10px" }}>
                        <Button
                          className="rounded-circle btn-icon"
                          color="none"
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdateBlacklistedGuests(element?._id);
                          }}
                        >
                          <FileText
                            color={reactFeatherIcon.iconColor}
                            size={reactFeatherIcon.iconSize}
                          />
                        </Button>
                      </td>
                      <td>{element?.hostel?.name}</td>
                      <td>{element?.reservationID}</td>
                      <td>{element?.name}</td>
                      <td>{element?.email}</td>
                      <td>{element?.mobile}</td>
                      <td>{element?.documentID}</td>
                      <td>
                        <Badge
                          color={
                            element?.status?.includes("Approved")
                              ? "success"
                              : element?.status?.includes("Rejected")
                              ? "danger"
                              : element?.status?.includes("Reversed")
                              ? "info"
                              : "warning"
                          }
                          className="pulse"
                        >
                          {element?.status}
                        </Badge>
                      </td>
                      <td>
                        <Badge
                          color={
                            element?.severity === "Low"
                              ? "success"
                              : // : element?.severity === "Moderate"
                                // ? "warning"
                                "danger"
                          }
                        >
                          {element?.severity}
                        </Badge>
                      </td>
                      <td>{element?.type}</td>
                      <td>{element?.reason}</td>
                      <td style={{ textAlign: "center" }}>
                        {element?.supportingEvidence?.length > 0 ? (
                          <Image
                            size={24}
                            onClick={() =>
                              handleImagePopup(element.supportingEvidence)
                            }
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          "-"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
              No request to show
            </h2>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default BlacklistedGuestsTable;
