import React, { useEffect, useMemo, useState } from "react";
import Table from "./table";
import { generateDateRange, generateFormattedDates } from "./helpers";
import { useGetRoomRates } from "../../api/CompetitorRates/CompetitorRatesDataSource";
import Filters from "./filter";
import { useGetHostelsNameandId } from "../../api/dataSource";
import AppLoader from "./loader";
import toaster from "../../@core/components/common/Toaster";
import { Col, Row } from "reactstrap";

const RoomRatesComparison = () => {
  // Dates for the table header and date ranges for mapping
  const [datesToShowToTableHeader, setDatesToShowToTableHeader] = useState(
    generateFormattedDates(new Date(), 8)
  );
  const [dateRangesForMap, setDateRangesForMap] = useState(
    generateDateRange(new Date(), null, 8)
  );

  // Temporary state for date range selection
  const [tempDateRanges, setTempDateRanges] = useState(dateRangesForMap);

  // Filters
  const [selectedOccupancy, setSelectedOccupancy] = useState({
    value: 1,
    label: "1",
  });
  const [hostel, setHostel] = useState({}); // not a multiselect
  console.log(hostel);

  // Variables for API call

  const { fetchRoomRates, isLoading, data } = useGetRoomRates();
  const roomData = data?.getCompetitorByRoomNamesByFilters?.data;
  console.log(isLoading);
  console.log(roomData);
  const { data: allHostelsData } = useGetHostelsNameandId();

  const handleFetch = () => {
    fetchRoomRates({
      variables: {
        filterRoomRates: {
          from: dateRangesForMap[0],
          to: dateRangesForMap.at(-1),
          hostels: "62725e2d5a1c65e71f472201",
          occupency: selectedOccupancy?.value ? selectedOccupancy?.value : 1,
        },
      },
    });
  };

  // useEffect(() => {
  //   handleFetch();
  // }, []);

  const HostelsList = useMemo(() => {
    return allHostelsData?.getHostelsNameandId?.map((item) => ({
      value: item?._id,
      label: item?.name,
    }));
  }, [allHostelsData]);

  const handleDateRangeChange = (selectedDates) => {
    const dateRanges = generateDateRange(selectedDates[0], selectedDates[1]);
    setTempDateRanges(dateRanges); // Set temporary date range
  };

  const handleOccupancyChange = (selectedOption) => {
    setSelectedOccupancy(selectedOption);
  };

  const handleHostelChange = (selectedOption) => {
    setHostel(selectedOption);
  };

  const handleSearch = () => {
    if (!hostel?.value) {
      return toaster("error", "Please select Hostel");
    }
    // Update the date ranges and table header dates using tempDateRanges
    setDateRangesForMap((prevDateRanges) => {
      const newDateRanges = [...tempDateRanges]; // Use the latest tempDateRanges
      setDatesToShowToTableHeader(
        generateFormattedDates(newDateRanges[0], newDateRanges.length)
      );

      // Trigger the API call with updated filters
      fetchRoomRates({
        variables: {
          filterRoomRates: {
            hostel: hostel?.value,
            occupency: selectedOccupancy?.value ? selectedOccupancy?.value : 1,
            from: newDateRanges[0],
            to: newDateRanges.at(-1),
          },
        },
      });

      return newDateRanges; // Update the state
    });
  };

  const handleReset = () => {
    // Reset all filters and dates in one click
    setSelectedOccupancy({ value: 1, label: "1" });
    setHostel({});

    const newDateRangesForMap = generateDateRange(new Date(), null, 8);
    setTempDateRanges(newDateRangesForMap); // Reset temp date range
    setDateRangesForMap(newDateRangesForMap); // Update the final date ranges
    setDatesToShowToTableHeader(generateFormattedDates(new Date(), 8));

    // Trigger the API with reset values
    fetchRoomRates({
      variables: {
        filterRoomRates: {
          from: newDateRangesForMap[0],
          to: newDateRangesForMap.at(-1),
          hostel: hostel?.value,
          occupency: 1,
        },
      },
    });
  };

  //   const handleReset = () => {
  //     setDatesToShowToTableHeader(generateFormattedDates(new Date(), 8));
  //     setDateRangesForMap(generateDateRange(new Date(), null, 8));
  //     setTempDateRanges(dateRangesForMap);
  //     setSelectedCompetitors([]);
  //     setSelectedPlatform(["All"]);
  //     setSelectedOccupancy({ value: 1, label: "1" });
  //     setHostel({});
  //   };

  // if(isLoading){
  //   return <AppLoader/>
  // }

  return (
    <>
      <Row>
        <Col>
          <Filters
            handleDateRangeChange={handleDateRangeChange}
            reviewDateFrom={tempDateRanges[0]}
            reviewDateTo={tempDateRanges.at(-1)}
            selectedOccupancy={selectedOccupancy}
            handleOccupancyChange={handleOccupancyChange}
            hostelsList={HostelsList}
            handleHostelChange={handleHostelChange}
            hostel={hostel}
            handleSearch={handleSearch}
            handleReset={handleReset}
          />
        </Col>
      </Row>
      <Row>
        <Col>
        <main style={{maxWidth : "100%" , overflow : "auto"}}>
          <Table
            dateRanges={dateRangesForMap}
            datesToShowToTableHeader={datesToShowToTableHeader}
            data={roomData}
            isloading={false}
            isHostelSelected={!!hostel?.value}
            />
            </main>
        </Col>
      </Row>
    </>
  );
};

export default RoomRatesComparison;
