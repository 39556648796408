import { useLazyQuery } from "@apollo/client"
import { GET_ROOM_RATES } from "./Queries"
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { startLoading, stopLoading } from "@store/loader/loader.action";


export const useLoadingHandler = (loading) => {
    const dispatch = useDispatch();
  
    useEffect(() => {
      loading ? dispatch(startLoading()) : dispatch(stopLoading());
    }, [dispatch, loading]);
  };

export const useGetRoomRates = () =>{
    const [fetchRoomRates , {error ,called , loading, data}] = useLazyQuery(GET_ROOM_RATES);
    const isLoading = called && loading
    useLoadingHandler(isLoading);
    return {fetchRoomRates , isLoading , error , data}
}